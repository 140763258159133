import gql from "graphql-tag";

export const MANAGER_APPROVER_EXPENSE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      allPendingApprovalExpenseRequests {
        id
        note
        approved
        nextApprover
        createdDateTime
        expense {
          id
          name
          description
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          reporter {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          abolishDateTime
          abolishReason
          expenseItems {
            id
            name
            description
            paymentType
            attachment
            publicURL
            dateTime
            abolished
            abolishReason
            requested {
              amount
              code
            }
            approved {
              amount
              code
            }
            expenseType {
              name
            }
          }
          expenseRequestProcesses {
            approved
            note
            approver {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            eventDateTime
            nextApprover
          }
          requestStatus
          description
          expenseManagement {
            name
            project {
              name
            }
          }
          allRequested {
            amount
            code
          }
          allApproved {
            amount
            code
          }
        }
      }
    }
  }
`;
